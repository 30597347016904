import { default as createbBZ11RVuPDMeta } from "/opt/buildhome/repo/pages/contact/create.vue?macro=true";
import { default as forgot_45passwordcBWiw0xyOQMeta } from "/opt/buildhome/repo/pages/forgot-password.vue?macro=true";
import { default as createrL3sNxL254Meta } from "/opt/buildhome/repo/pages/form/create.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as indexjJXRPjWvhQMeta } from "/opt/buildhome/repo/pages/report/[id]/index.vue?macro=true";
import { default as creater0zV7U6xfOMeta } from "/opt/buildhome/repo/pages/report/create.vue?macro=true";
import { default as indexShpfogGWDEMeta } from "/opt/buildhome/repo/pages/report/index.vue?macro=true";
import { default as reset_45passwordR3tuU8TAkoMeta } from "/opt/buildhome/repo/pages/reset-password.vue?macro=true";
import { default as signup71EVNEoLpMMeta } from "/opt/buildhome/repo/pages/signup.vue?macro=true";
export default [
  {
    name: createbBZ11RVuPDMeta?.name ?? "contact-create",
    path: createbBZ11RVuPDMeta?.path ?? "/contact/create",
    meta: createbBZ11RVuPDMeta || {},
    alias: createbBZ11RVuPDMeta?.alias || [],
    redirect: createbBZ11RVuPDMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/contact/create.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordcBWiw0xyOQMeta?.name ?? "forgot-password",
    path: forgot_45passwordcBWiw0xyOQMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordcBWiw0xyOQMeta || {},
    alias: forgot_45passwordcBWiw0xyOQMeta?.alias || [],
    redirect: forgot_45passwordcBWiw0xyOQMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: createrL3sNxL254Meta?.name ?? "form-create",
    path: createrL3sNxL254Meta?.path ?? "/form/create",
    meta: createrL3sNxL254Meta || {},
    alias: createrL3sNxL254Meta?.alias || [],
    redirect: createrL3sNxL254Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/form/create.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login4dfr6kRqBoMeta?.name ?? "login",
    path: login4dfr6kRqBoMeta?.path ?? "/login",
    meta: login4dfr6kRqBoMeta || {},
    alias: login4dfr6kRqBoMeta?.alias || [],
    redirect: login4dfr6kRqBoMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexjJXRPjWvhQMeta?.name ?? "report-id",
    path: indexjJXRPjWvhQMeta?.path ?? "/report/:id()",
    meta: indexjJXRPjWvhQMeta || {},
    alias: indexjJXRPjWvhQMeta?.alias || [],
    redirect: indexjJXRPjWvhQMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/report/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: creater0zV7U6xfOMeta?.name ?? "report-create",
    path: creater0zV7U6xfOMeta?.path ?? "/report/create",
    meta: creater0zV7U6xfOMeta || {},
    alias: creater0zV7U6xfOMeta?.alias || [],
    redirect: creater0zV7U6xfOMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/report/create.vue").then(m => m.default || m)
  },
  {
    name: indexShpfogGWDEMeta?.name ?? "report",
    path: indexShpfogGWDEMeta?.path ?? "/report",
    meta: indexShpfogGWDEMeta || {},
    alias: indexShpfogGWDEMeta?.alias || [],
    redirect: indexShpfogGWDEMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/report/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordR3tuU8TAkoMeta?.name ?? "reset-password",
    path: reset_45passwordR3tuU8TAkoMeta?.path ?? "/reset-password",
    meta: reset_45passwordR3tuU8TAkoMeta || {},
    alias: reset_45passwordR3tuU8TAkoMeta?.alias || [],
    redirect: reset_45passwordR3tuU8TAkoMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: signup71EVNEoLpMMeta?.name ?? "signup",
    path: signup71EVNEoLpMMeta?.path ?? "/signup",
    meta: signup71EVNEoLpMMeta || {},
    alias: signup71EVNEoLpMMeta?.alias || [],
    redirect: signup71EVNEoLpMMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/signup.vue").then(m => m.default || m)
  }
]